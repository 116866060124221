import React, { useContext } from "react";
import PropTypes from "prop-types";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";
import { Col, Row, Table } from "reactstrap";
import moment from "moment";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";

function CSInvoiceOrder(props) {
  const { invoiceID } = props;
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const { data: invoice } = useQuery(
    `/shop/${shopID}/Order/${invoiceID}`,
    async () => Axios.get(`/shop/${shopID}/Order/${invoiceID}`)
  );

  return (
    <div>
      <div className="ml-5 mr-5 pl-4 pr-4">
        <div>
          <Row className="">
            <Col md="7">
              <div>
                <p
                  className="text-dark text-capitalize"
                  style={{ fontSize: "18px", fontWeight: "900" }}
                >
                  Invoice
                </p>
                <p className="text-dark mb-0" style={{ fontSize: "15px" }}>
                  Invoice NO :&nbsp;
                  {`INV${invoice?.data.Invoice}`}
                </p>
                <p className="text-dark mb-0" style={{ fontSize: "15px" }}>
                  Invoice Date:&nbsp;
                  {`${moment(invoice?.data.OrderDate).format("YYYY-MM-DD")}`}
                </p>
                <p className="text-dark mb-0" style={{ fontSize: "15px" }}>
                  Order NO:&nbsp;
                  {`#${invoice?.data.ID}`}
                </p>
                <p className="text-dark mb-0" style={{ fontSize: "15px" }}>
                  Order Date:&nbsp;
                  {`${moment(invoice?.data.OrderDate).format("YYYY-MM-DD")}`}
                </p>
              </div>
            </Col>
            <Col md="5">
              <p
                className="mb-0"
                style={{
                  fontSize: "15px",
                  color: "#000000",
                }}
              >
                Payment Method:&nbsp;{invoice?.data.PaymentGateway}
              </p>
              <p
                className="mb-0"
                style={{ fontSize: "15px", color: "#000000" }}
              >
                Order Paid Date:&nbsp;
                {`${moment(invoice?.data.OrderDate).format("YYYY-MM-DD")}`}
              </p>
              <p
                className="mb-0"
                style={{ fontSize: "15px", color: "#000000" }}
              >
                Total:&nbsp;
                <span
                  style={{
                    fontSize: "20px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  <CurrencyFormat
                    decimal={2}
                    price={invoice?.data.TotalOrderCost}
                    currency="MYR"
                  />
                </span>
              </p>
            </Col>
          </Row>
        </div>
        <div className="mt-4">
          <Row>
            <Col md="7">
              <div>
                <h6
                  className="font-weight-bold text-left text-capitalize"
                  style={{ fontSize: "14px", color: "#000000" }}
                >
                  Order By
                </h6>
                <p
                  className="text-capitalize font-weight-bold"
                  style={{ fontSize: "15px", color: "#000000" }}
                >
                  {invoice?.data.BillingName}
                </p>
                <p style={{ fontSize: "15px", color: "#000000" }}>
                  {`H/P: ${invoice?.data.BillingContact}`}
                  <br />
                  {invoice?.data.Email}
                  <br />
                  {invoice?.data.BillingAddress}
                  <br />
                </p>
              </div>
            </Col>
            <Col md="5">
              <div>
                <h6
                  className="font-weight-bold text-capitalize"
                  style={{ fontSize: "14px", color: "#000000" }}
                >
                  Shipped To
                </h6>
                <p
                  className="text-capitalize font-weight-bold"
                  style={{ fontSize: "15px", color: "#000000" }}
                >
                  {invoice?.data.ShippingName}
                </p>
                <p style={{ fontSize: "15px", color: "#000000" }}>
                  {`H/P: ${invoice?.data.ShippingContact}`}
                  <br />
                  {invoice?.data.Email}
                  <br />
                  {invoice?.data.ShippingAddress}
                  <br />
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <p
          className="mt-4 pt-3 pb-2 font-weight-bold "
          style={{ fontSize: "14px", color: "#000000" }}
        >
          Product Details :
        </p>
        <Table responsive className="m-0 ">
          <tbody>
            {invoice?.data.Product.map((orderp, idx) => (
              <tr
                key={orderp.ID}
                className="text-dark"
                {...(idx !== invoice.data.Product.length - 1 && {
                  style: {
                    borderBottom: "1px solid #000000",
                  },
                })}
              >
                <td className="border-0">
                  <Row>
                    <Col md="2">
                      <img
                        alt="..."
                        className=""
                        src={orderp.ImageS3Url}
                        width="100%"
                        height="100%"
                      />
                    </Col>
                    <Col md="10">
                      {" "}
                      <p className="text-dark " style={{ fontSize: "15px" }}>
                        SKU:&nbsp;{orderp.VariationSKU}
                      </p>
                      <p
                        className="font-weight-bold text-dark"
                        style={{ fontSize: "15px" }}
                      >
                        {orderp.Name}
                      </p>{" "}
                      <Row>
                        {orderp.Attribute.map((variation) => (
                          <strong
                            className="text-capitalize font-weight-bold text-dark"
                            key={variation.Attribute}
                          >
                            <Col md="12" style={{ fontSize: "15px" }}>
                              {variation.Attribute}
                            </Col>
                            <Col
                              md="12"
                              className="text-center font-weight-bold"
                              style={{ fontSize: "15px" }}
                            >
                              {variation.Value}
                            </Col>
                          </strong>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </td>
                <td className="border-0">
                  <p
                    className="text-center font-weight-bold align-items-end  text-dark"
                    style={{ fontSize: "15px", marginTop: "73px" }}
                  >
                    Quantity
                  </p>
                  <p
                    className="text-center font-weight-bold text-dark"
                    style={{ fontSize: "15px" }}
                  >
                    {orderp.ProductOrderCount}
                  </p>
                </td>
                <td className="border-0">
                  <p
                    className="text-right font-weight-bold align-items-end text-dark"
                    style={{
                      marginTop: "75px",
                      fontSize: "15px",
                      marginRight: "50px",
                      paddingRight: "30px",
                    }}
                  >
                    Total
                  </p>
                  <p
                    className="text-right font-weight-bold text-dark"
                    style={{ fontSize: "15px" }}
                  >
                    <CurrencyFormat
                      price={orderp.TotalOrderCost}
                      currency="MYR"
                    />
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="text-left mt-4 ml-4 mr-4 pl-5 pr-5 pb-0">
          <Row className="">
            <Col md="12" className="">
              <p
                className="text-dark"
                style={{ fontSize: "15px", fontWeight: "900" }}
              >
                Order Summary
              </p>
              <Row lg="2" md="2" sm="2" xs="2" className="mb-2">
                <Col>
                  <p
                    className="text-dark font-weight-normal"
                    style={{ fontSize: "20px" }}
                  >
                    Subtotal :
                  </p>
                </Col>
                <Col className="text-right">
                  <h5 className="font-weight-normal text-dark">
                    <CurrencyFormat
                      price={invoice?.data.SubTotal}
                      currency="MYR"
                    />
                  </h5>
                </Col>
                <Col>
                  <p
                    className="text-dark font-weight-normal"
                    style={{ fontSize: "20px" }}
                  >
                    Processing Fee :
                  </p>
                </Col>
                <Col className="text-right">
                  <h5 className="font-weight-normal text-dark">
                    <CurrencyFormat
                      price={invoice?.data.ProcessingFee}
                      currency="MYR"
                    />
                  </h5>
                </Col>
                <Col>
                  <p className="text-dark " style={{ fontSize: "20px" }}>
                    Shipping Fee :
                  </p>
                </Col>
                <Col className="text-right">
                  <h5 className="font-weight-normal text-dark">
                    <CurrencyFormat
                      price={invoice?.data.ShippingFee}
                      currency="MYR"
                    />
                  </h5>
                </Col>

                <Col>
                  <p
                    className="text-dark font-weight-normal"
                    style={{ fontSize: "20px" }}
                  >
                    Coupons Use :
                  </p>
                </Col>
                <Col className="text-right">
                  <h5 className="font-weight-normal text-dark">
                    <CurrencyFormat
                      price={invoice?.data.Discount}
                      currency="-MYR"
                    />
                  </h5>
                </Col>
                <Col>
                  <p
                    className="text-dark font-weight-normal"
                    style={{ fontSize: "20px" }}
                  >
                    E-Wallet :
                  </p>
                </Col>
                <Col className="text-right">
                  <h5 className="font-weight-normal text-dark ">
                    <CurrencyFormat
                      price={invoice?.data.Ewallet}
                      currency="-MYR"
                    />
                  </h5>
                </Col>
              </Row>
              <div>
                <hr
                  style={{
                    backgroudColor: "#000000",
                    border: "1px solid #000000",
                    width: "100%",
                  }}
                />
              </div>
              <Row lg="2" md="2" sm="2" xs="2" className="mt-2">
                <Col>
                  <p
                    className="text-dark font-weight-normal"
                    style={{ fontSize: "20px" }}
                  >
                    Total :
                  </p>
                </Col>
                <Col className="text-right">
                  <h5 className="font-weight-normal text-dark">
                    <CurrencyFormat
                      price={invoice?.data.TotalOrderCost}
                      currency="MYR"
                    />
                  </h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
CSInvoiceOrder.propTypes = {
  invoiceID: PropTypes.string.isRequired,
};
export default CSInvoiceOrder;
