import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

function AudioPlayer({ src }) {
  const audioRef = useRef(null);

  useEffect(() => {
    const playAudio = async () => {
      if (audioRef.current) {
        try {
          await audioRef.current.play();
        } catch (error) {
          console.error("Autoplay was prevented:", error);
          // This is a good place to update the UI to show a play button
        }
      }
    };

    playAudio();
  }, []);

  return (
    <div>
      <audio ref={audioRef} controls hidden loop>
        <track kind="captions" src={src} srcLang="en" label="English" />
        <source src={src} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>

      {/* Other controls can be added here */}
    </div>
  );
}

export default AudioPlayer;
AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
};
